import {useMutation} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import showHttpErrorToast from '@common/utils/http/show-http-error-toast';
import toast from '@common/ui/toast/toast';
import {PurchaseCode} from '@app/agent/purchase-code';

function deactivePurchaseFromDomain(purchase: PurchaseCode): Promise<Response> {
    return apiClient
        .delete(`users/${purchase.user_id}/envato/deactive-purchase-from-domain`, {
            params: {
                purchase_code: purchase.code,
            }
        })
        .then(r => r.data);
}


export default function useDeactivePurchaseFromDomain() {
    return useMutation({
        mutationFn: (purchase: PurchaseCode)  => deactivePurchaseFromDomain(purchase),
        onSuccess: async response => {
            await queryClient.invalidateQueries({queryKey: ['purchases']});
            toast(`Purchase code from domain has been deactived.`);
        },
        onError: err => showHttpErrorToast(err),
    });
}
