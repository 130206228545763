import {Trans} from '@common/i18n/trans';
import {User} from '@common/auth/user';
import AccountSettingsPanel from '@common/auth/ui/account-settings/account-settings-panel';
import useUserPurchases from '@app/account-settings-purchases-panel/use-user-purchases';
import {UseQueryResult} from '@tanstack/react-query';
import {PurchaseCode} from '@app/agent/purchase-code';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import PageErrorMessage from '@common/errors/page-error-message';
import React, {Fragment} from 'react';
import clsx from 'clsx';
import {FormattedDate} from '@common/i18n/formatted-date';
import IllustratedMessage from '@common/ui/images/illustrated-message';
import {EnvatoIcon} from '@common/icons/social/envato';
import {Button} from '@common/ui/buttons/button';
import useSyncEnvatoPurchases from '@app/agent/agent-ticket-page/user-details-sidebar/requests/use-sync-envato-purchases';
import useSyncManualEnvatoPurchases
  from '@app/agent/agent-ticket-page/user-details-sidebar/requests/use-sync-manual-envato-purchases';
import DialogTrigger from '@common/ui/overlays/dialog/dialog-trigger';
import AddPurchaseCodeDialog from '@app/account-settings-purchases-panel/add-purchase-code-dialog';
import ConfirmationDialog from "@common/ui/overlays/dialog/confirmation-dialog";
import useDeactivePurchaseFromDomain
  from "@app/agent/agent-ticket-page/user-details-sidebar/requests/use-deactive-purchase-from-domain";

interface Props {
  user: User;
}
export default function AccountSettingsPurchasesPanel({}: Props) {
  const query = useUserPurchases('me');
  const syncPurchases = useSyncEnvatoPurchases();
  const syncManualPurchases = useSyncManualEnvatoPurchases();
  const purchaseCodes = query.data?.purchases || [];

  return (
    <AccountSettingsPanel
      id="purchases"
      title={<Trans message="Your purchases" />}
      actions={
        <Fragment>
          <DialogTrigger type="modal">
            <Button variant="outline" className="mr-12">
              <Trans message="Add purchase code" />
            </Button>
            <AddPurchaseCodeDialog userId="me" />
          </DialogTrigger>
          <Button
              variant="flat"
              className="mr-12"
              color="primary"
              onClick={() => syncManualPurchases.mutate({
                purchaseCodes,
                userId: 'me'
              })}
              disabled={syncManualPurchases.isPending || !query.data?.purchases?.length}
          >
            <Trans message="Sync purchase codes"/>
          </Button>
          <Button
            variant="flat"
            color="primary"
            onClick={() => syncPurchases.mutate({userId: 'me'})}
            disabled={syncPurchases.isPending}
          >
            <Trans message="Import purchases from envato" />
          </Button>
        </Fragment>
      }
    >
      <Content query={query} />
    </AccountSettingsPanel>
  );
}

interface ContentProps {
  query: UseQueryResult<{purchases: PurchaseCode[]}>;
}
function Content({query}: ContentProps) {
  const deactivePurchaseFromDomain = useDeactivePurchaseFromDomain();

  if (query.data) {
    if (!query.data.purchases.length) {
      return (
        <div>
          <IllustratedMessage
            size="sm"
            imageHeight="h-auto"
            title={<Trans message="Could not find any purchases" />}
            description={
              <Trans message="Try importing purchases from envato with the button below." />
            }
            imageMargin="mb-10"
            image={
              <div>
                <EnvatoIcon viewBox="0 0 50 50" className="text-envato" />
              </div>
            }
          />
        </div>
      );
    }
    return (
      <div>
        {query.data.purchases.map(purchase => (
          <div
            key={purchase.id}
            className="relative flex items-center gap-12 rounded py-10"
          >
            <img src={purchase.image} alt="" className="h-64 w-64 rounded" />
            <div className="min-w-0 text-sm w-full">
              <a
                  href={purchase.url}
                  target="_blank"
                  rel="noreferrer"
                  className="overflow-hidden overflow-ellipsis whitespace-nowrap font-semibold hover:text-primary"
              >
                {purchase.item_name}
              </a>
              <div className="my-4 text-xs">
                <Trans
                    message="Purchase code: :code"
                    values={{code: purchase.code}}
                />
              </div>
              <div
                  className={clsx(
                      'text-xs text-muted mb-1',
                      purchase.support_expired && 'line-through',
                  )}
              >
                {purchase.supported_until ? (
                    <span>
                    <Trans message="Supported until:"/>{' '}
                      <FormattedDate date={purchase.supported_until}/>
                  </span>
                ) : (
                    <Trans message="Support expired"/>
                )}
              </div>
              {purchase.domain ? (
                  <div className="flex items-center justify-between bg-hover p-[5px]">
                    <div className="my-4 text-xs flex items-center">
                      <div className="inline-block rounded-full mr-[5px] w-[10px] h-[10px] bg-[#14AE5C]"></div>
                      <Trans
                          message="Activated on: :domain"
                          values={{domain: purchase.domain}}
                      />
                    </div>
                    <DialogTrigger
                        type="modal"
                        onClose={isConfirmed => {
                          if (isConfirmed) {
                            deactivePurchaseFromDomain.mutate(purchase);
                          }
                        }}
                    >
                      <a className={clsx(
                          'text-danger hover:cursor-pointer',
                          deactivePurchaseFromDomain.isPending && 'pointer-events-none cursor-not-allowed',
                      )}>
                        <Trans message="Remove purchase from domain"/>
                      </a>
                      {/*<Button variant="flat" color="danger" disabled={deactivePurchaseFromDomain.isPending}>*/}
                      {/*    <Trans message="Remove purchase from domain" />*/}
                      {/*</Button>*/}
                      <ConfirmationDialog
                          isDanger
                          title={<Trans message="Are you sure?"/>}
                          body={
                            <Trans
                                message="Are you sure you want to remove the purchase code from domain :domain?"
                                values={{domain: purchase.domain}}/>
                          }
                          confirm={<Trans message="Yes, remove it"/>}
                      />
                    </DialogTrigger>
                  </div>
              ) : ''}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (query.isLoading) {
    return (
      <div className="flex min-h-82 items-center justify-center">
        <ProgressCircle isIndeterminate />
      </div>
    );
  }

  return <PageErrorMessage />;
}
